import axios from '../axios';

export const show = () => {
  return axios.get(`/free-delivery`);
};

export const submit = (payload) => {
  return axios.post('/free-delivery', payload);
};

export const updateFreeDeliveryStatus = (id, status) => {
  return axios.put(`/free-delivery/${id}`, { is_active: status });
};

export const deleteFreeDelivery = (id) => {
  return axios.delete(`/free-delivery/${id}`);
};
