<template>
  <div ref="formContainer">
    <!-- Add New Free Delivery Form -->
    <b-card>
      <h4 class="mb-4">{{ isEditMode ? 'Edytuj darmową dostawę' : 'Dodaj nową darmową dostawę' }}</h4>
      <b-form @submit.prevent="validationForm" class="">
        <div class="form-group mb-2">
          <v-label> Typ </v-label>
          <v-select
            v-model="formData.type"
            :options="typeOptions"
            label="label"
            :reduce="(item) => item.value"
            :clearable="false"
            :searchable="false" />
        </div>

        <div v-if="formData.type === 'category'" class="form-group mb-2">
          <v-label> Kategorie </v-label>
          <v-select
            v-model="selectedCategories"
            :options="categories"
            :reduce="(item) => item.id"
            label="name"
            :clearable="false"
            :searchable="true"
            :filterable="true"
            :multiple="false"
            :close-on-select="false"
            @option:selected="handleCategorySelected" />

          <div v-if="formData.selectedCategories.length > 0" class="mt-2">
            <h6>Wybrane kategorie:</h6>
            <div class="selected-categories-grid">
              <div v-for="category in formData.selectedCategories" :key="category.id" class="selected-category-item">
                <div class="category-content">
                  <span>{{ category.name }}</span>
                  <b-button size="sm" variant="link" class="category-remove-btn" @click="removeCategory(category)">
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="formData.type === 'overcategory'" class="form-group mb-2">
          <v-label> Nadkategorie </v-label>
          <v-select
            v-model="selectedOvercategories"
            :options="overcategories"
            :reduce="(item) => item.id"
            label="name"
            :clearable="false"
            :searchable="true"
            :filterable="true"
            :multiple="false"
            :close-on-select="false"
            @option:selected="handleOvercategorySelected" />

          <div v-if="formData.selectedOvercategories.length > 0" class="mt-2">
            <h6>Wybrane nadkategorie:</h6>
            <div class="selected-categories-grid">
              <div
                v-for="overcategory in formData.selectedOvercategories"
                :key="overcategory.id"
                class="selected-category-item">
                <div class="category-content">
                  <span>{{ overcategory.name }}</span>
                  <b-button
                    size="sm"
                    variant="link"
                    class="category-remove-btn"
                    @click="removeOvercategory(overcategory)">
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="formData.type === 'subcategory'" class="form-group mb-2">
          <v-label> Podkategorie </v-label>
          <v-select
            v-model="selectedSubcategories"
            :options="subcategories"
            :reduce="(item) => item.id"
            label="name"
            :clearable="false"
            :searchable="true"
            :filterable="true"
            :multiple="false"
            :close-on-select="false"
            @option:selected="handleSubcategorySelected" />

          <div v-if="formData.selectedSubcategories.length > 0" class="mt-2">
            <h6>Wybrane podkategorie:</h6>
            <div class="selected-categories-grid">
              <div
                v-for="subcategory in formData.selectedSubcategories"
                :key="subcategory.id"
                class="selected-category-item">
                <div class="category-content">
                  <span>{{ subcategory.name }}</span>
                  <b-button
                    size="sm"
                    variant="link"
                    class="category-remove-btn"
                    @click="removeSubcategory(subcategory)">
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="formData.type === 'indexes'" class="form-group mb-2">
          <v-label> Indeksy </v-label>
          <v-select
            v-model="selectedIndexes"
            :options="indexes"
            :reduce="(item) => item.id"
            label="name"
            :clearable="false"
            :searchable="true"
            :filterable="false"
            :multiple="false"
            :close-on-select="false"
            @option:selected="handleIndexSelected"
            @search="searchIndexes"
            :loading="isLoadingIndexes">
            <template #no-options> Wpisz minimum 2 znaki, aby wyszukać produkty </template>
            <template #spinner>
              <div class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Ładowanie...</span>
              </div>
            </template>
          </v-select>

          <div v-if="formData.selectedIndexes.length > 0" class="mt-2">
            <h6>Wybrane indeksy:</h6>
            <div class="selected-indexes-grid">
              <div v-for="index in formData.selectedIndexes" :key="index.id" class="selected-index-item">
                <div class="index-content">
                  <span>{{ index.name }}</span>
                  <b-button size="sm" variant="link" class="index-remove-btn" @click="removeIndex(index)">
                    <feather-icon icon="XIcon" />
                  </b-button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="form-group mb-2">
          <v-label> Liczenie elementów </v-label>
          <v-select
            v-model="formData.itemsCount"
            :options="itemsCountOptions"
            label="label"
            :reduce="(item) => item.value"
            :clearable="false"
            :searchable="false" />
          <span v-if="formData.type === 'category' && formData.itemsCount === 'strict'" class="text-muted"
            >Wszystkie elementy w koszyku muszą być tej samej kategorii</span
          >
          <span v-if="formData.type === 'indexes' && formData.itemsCount === 'strict'" class="text-muted"
            >W koszyku muszą być tylko wybrane indeksy</span
          >
          <span v-if="formData.type === 'indexes' && formData.itemsCount === 'cart'" class="text-muted"
            >W koszyku musi się znajdować przynajmniej jeden z wybranych indeksów</span
          >
          <span v-if="formData.type === 'category' && formData.itemsCount === 'cart'" class="text-muted"
            >W koszyku musi się znajdować przynajmniej jeden element tej kategorii</span
          >
          <span v-if="formData.type === 'overcategory' && formData.itemsCount === 'strict'" class="text-muted"
            >Wszystkie elementy w koszyku muszą być tej samej nadkategorii</span
          >
          <span v-if="formData.type === 'overcategory' && formData.itemsCount === 'cart'" class="text-muted"
            >W koszyku musi się znajdować przynajmniej jeden element tej nadkategorii</span
          >
          <span v-if="formData.type === 'subcategory' && formData.itemsCount === 'strict'" class="text-muted"
            >Wszystkie elementy w koszyku muszą być tej samej podkategorii</span
          >
          <span v-if="formData.type === 'subcategory' && formData.itemsCount === 'cart'" class="text-muted"
            >W koszyku musi się znajdować przynajmniej jeden element tej podkategorii</span
          >
        </div>

        <div class="form-group mb-2">
          <div>
            <v-label>
              Darmowa dostawa będzie
              {{ formData.is_active ? 'aktywna' : 'nieaktywna' }}
            </v-label>
            <b-form-checkbox v-model="formData.is_active" name="is-active-checkbox" switch size="lg"> </b-form-checkbox>
          </div>
        </div>

        <div class="form-group mb-2">
          <v-label> Data ważności </v-label>
          <b-form-datepicker
            v-model="formData.valid_until"
            locale="pl"
            placeholder="Wybierz datę ważności"
            class="mb-1"
            style="width: 50%"
            :date-format-options="{ year: 'numeric', month: 'long', day: 'numeric' }"
            reset-button
            reset-button-label="Wyczyść"
            label-no-date-selected="Nie wybrano daty"
            selected-variant="primary"></b-form-datepicker>
          <small class="text-muted">Pozostaw puste dla bezterminowej darmowej dostawy</small>
        </div>

        <div class="mt-4">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" type="submit" variant="primary" class="mr-1">
            {{ isEditMode ? 'Zapisz zmiany' : 'Zapisz' }}
          </b-button>
          <b-button
            v-if="isEditMode"
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="button"
            variant="outline-secondary"
            @click="resetForm"
            class="mr-1">
            Anuluj
          </b-button>
        </div>
      </b-form>
    </b-card>
    <div v-if="freeDeliveries.length > 0" class="mb-4">
      <div class="free-deliveries-grid">
        <b-card
          v-for="delivery in freeDeliveries"
          :key="delivery.id"
          class="free-delivery-card p-0"
          no-body
          :class="{ 'inactive-card': !delivery.is_active }">
          <!-- Card Header -->
          <div class="card-header-section">
            <div class="status-indicator" :class="delivery.is_active ? 'status-active' : 'status-inactive'"></div>
            <div class="delivery-type-badge">
              <b-badge pill variant="primary" class="delivery-type mt-3">
                {{
                  delivery.type === 'category'
                    ? 'Kategoria'
                    : delivery.type === 'overcategory'
                    ? 'Nadkategoria'
                    : delivery.type === 'subcategory'
                    ? 'Podkategoria'
                    : 'Indeksy'
                }}
              </b-badge>
            </div>
          </div>

          <!-- Card Content -->
          <div class="card-body-content p-3">
            <div class="delivery-title mb-3">
              <h5 v-if="delivery.type === 'category'" class="mb-0">
                Kategorie ({{ delivery.category_ids ? delivery.category_ids.length : 0 }})
              </h5>
              <h5 v-else-if="delivery.type === 'overcategory'" class="mb-0">
                Nadkategorie ({{ delivery.overcategory_ids ? delivery.overcategory_ids.length : 0 }})
              </h5>
              <h5 v-else-if="delivery.type === 'subcategory'" class="mb-0">
                Podkategorie ({{ delivery.subcategory_ids ? delivery.subcategory_ids.length : 0 }})
              </h5>
              <h5 v-else class="mb-0">Zestaw produktów ({{ delivery.indexes ? delivery.indexes.length : 0 }})</h5>

              <div class="delivery-status mt-2">
                <span class="delivery-status-text" :class="delivery.is_active ? 'text-success' : 'text-danger'">
                  {{ delivery.is_active ? 'Aktywna' : 'Nieaktywna' }}
                </span>
                <span v-if="delivery.valid_until" class="delivery-expiry ml-2">
                  <small class="text-muted">
                    <i class="feather icon-calendar mr-1"></i>
                    do {{ formatDate(delivery.valid_until) }}
                  </small>
                </span>
              </div>
            </div>

            <div class="delivery-details">
              <div class="delivery-rule mb-2">
                <span class="rule-label text-muted">Warunek dostawy:</span>
                <span class="rule-value font-weight-bold">
                  {{ getDeliveryRuleText(delivery) }}
                </span>
              </div>

              <div v-if="delivery.type === 'indexes'" class="indexes-container mt-3">
                <span class="indexes-title text-muted mb-1 d-block">Produkty objęte darmową dostawą:</span>
                <div class="delivery-indexes-grid">
                  <div v-for="index in delivery.indexes" :key="index" class="delivery-index-item">
                    <i class="feather icon-box mr-1"></i>
                    <span>{{ index }}</span>
                  </div>
                </div>
                <div v-if="delivery.indexes && delivery.indexes.length > 5" class="indexes-count text-right mt-1">
                  <small class="text-muted">Łącznie produktów: {{ delivery.indexes.length }}</small>
                </div>
              </div>

              <div v-if="delivery.type === 'category' && delivery.category_ids" class="categories-container mt-3">
                <span class="categories-title text-muted mb-1 d-block">Kategorie objęte darmową dostawą:</span>
                <div class="delivery-categories-grid">
                  <div v-for="category_id in delivery.category_ids" :key="category_id" class="delivery-category-item">
                    <i class="feather icon-folder mr-1"></i>
                    <span>{{ getCategoryName(category_id) }}</span>
                  </div>
                </div>
                <div
                  v-if="delivery.category_ids && delivery.category_ids.length > 5"
                  class="categories-count text-right mt-1">
                  <small class="text-muted">Łącznie kategorii: {{ delivery.category_ids.length }}</small>
                </div>
              </div>

              <div
                v-if="delivery.type === 'overcategory' && delivery.overcategory_ids"
                class="categories-container mt-3">
                <span class="categories-title text-muted mb-1 d-block">Nadkategorie objęte darmową dostawą:</span>
                <div class="delivery-categories-grid">
                  <div
                    v-for="overcategory_id in delivery.overcategory_ids"
                    :key="overcategory_id"
                    class="delivery-category-item">
                    <i class="feather icon-layers mr-1"></i>
                    <span>{{ getOvercategoryName(overcategory_id) }}</span>
                  </div>
                </div>
                <div
                  v-if="delivery.overcategory_ids && delivery.overcategory_ids.length > 5"
                  class="categories-count text-right mt-1">
                  <small class="text-muted">Łącznie nadkategorii: {{ delivery.overcategory_ids.length }}</small>
                </div>
              </div>

              <div v-if="delivery.type === 'subcategory' && delivery.subcategory_ids" class="categories-container mt-3">
                <span class="categories-title text-muted mb-1 d-block">Podkategorie objęte darmową dostawą:</span>
                <div class="delivery-categories-grid">
                  <div
                    v-for="subcategory_id in delivery.subcategory_ids"
                    :key="subcategory_id"
                    class="delivery-category-item">
                    <i class="feather icon-tag mr-1"></i>
                    <span>{{ getSubcategoryName(subcategory_id) }}</span>
                  </div>
                </div>
                <div
                  v-if="delivery.subcategory_ids && delivery.subcategory_ids.length > 5"
                  class="categories-count text-right mt-1">
                  <small class="text-muted">Łącznie podkategorii: {{ delivery.subcategory_ids.length }}</small>
                </div>
              </div>
            </div>
          </div>

          <!-- Card Actions -->
          <div class="card-actions">
            <b-button
              variant="outline-primary"
              size="sm"
              class="mr-1"
              :disabled="isEditMode"
              @click="toggleActiveStatus(delivery)">
              <feather-icon :icon="delivery.is_active ? 'PauseCircleIcon' : 'PlayCircleIcon'" size="16" class="mr-1" />
              {{ delivery.is_active ? 'Dezaktywuj' : 'Aktywuj' }}
            </b-button>

            <b-button
              variant="outline-secondary"
              size="sm"
              class="mr-1"
              :disabled="isEditMode"
              @click="editDelivery(delivery)">
              <feather-icon icon="EditIcon" size="16" class="mr-1" />
              Edytuj
            </b-button>

            <b-button variant="outline-danger" size="sm" :disabled="isEditMode" @click="deleteDelivery(delivery.id)">
              <feather-icon icon="TrashIcon" size="16" class="mr-1" />
              Usuń
            </b-button>
          </div>
        </b-card>
      </div>
    </div>

    <div v-else class="text-muted mb-4">Brak zdefiniowanych darmowych dostaw</div>
  </div>
</template>

<script>
import LoaderMixin from '@/mixins/LoaderMixin.vue';
import NotificationMixin from '@/mixins/NotificationMixin';
import { all as getSubcategories } from '@/http/product/subcategory';
import { all as getCategories } from '@/http/product/category';
import { all as getOvercategories } from '@/http/product/overcategory';
import { submit, show as getFreeDeliveries, updateFreeDeliveryStatus, deleteFreeDelivery } from '@/http/free-delivery';
import { quickSearch } from '@/http/product/product';
import { BFormDatepicker } from 'bootstrap-vue';

export default {
  components: {
    BFormDatepicker,
  },
  mixins: [LoaderMixin, NotificationMixin],

  data() {
    return {
      freeDeliveries: [],
      selectedIndexes: [],
      selectedCategories: [],
      selectedSubcategories: [],
      selectedOvercategories: [],
      categories: [],
      subcategories: [],
      overcategories: [],
      indexes: [],
      isLoadingIndexes: false,
      searchTimeout: null,
      isEditMode: false,
      editDeliveryId: null,
      typeOptions: [
        { label: 'Kategoria', value: 'category' },
        { label: 'Nadkategoria', value: 'overcategory' },
        { label: 'Podkategoria', value: 'subcategory' },
        { label: 'Indeksy', value: 'indexes' },
      ],
      itemsCountOptions: [
        { label: 'Wyłącznie produkty spełniające kryteria', value: 'strict' },
        { label: 'Wszystkie produkty w koszyku', value: 'cart' },
      ],
      formData: {
        type: 'category',
        selectedCategories: [],
        selectedOvercategories: [],
        selectedSubcategories: [],
        selectedIndexes: [],
        itemsCount: 'strict',
        is_active: true,
        valid_until: null,
      },
    };
  },

  created() {
    this.fetchCategories();
    this.fetchSubcategories();
    this.fetchOvercategories();
    this.fetchFreeDeliveries();
  },

  watch: {
    'formData.type': function (newType) {
      // Only reset selections when not in edit mode
      if (!this.isEditMode) {
        this.formData.selectedIndexes = [];
        this.selectedIndexes = null;
        this.formData.selectedCategories = [];
        this.selectedCategories = null;
        this.formData.selectedOvercategories = [];
        this.selectedOvercategories = null;
        this.formData.selectedSubcategories = [];
        this.selectedSubcategories = null;
      }
    },
  },

  methods: {
    fetchSubcategories() {
      return getSubcategories('pl')
        .then(({ data }) => {
          this.subcategories = data.data;
        })
        .catch((error) => {
          console.error('Error fetching subcategories:', error);
          this.showErrorNotification('Nie udało się pobrać podkategorii');
        });
    },

    fetchOvercategories() {
      return getOvercategories('pl')
        .then(({ data }) => {
          this.overcategories = data.data;
        })
        .catch((error) => {
          console.error('Error fetching overcategories:', error);
          this.showErrorNotification('Nie udało się pobrać nadkategorii');
        });
    },

    fetchCategories() {
      getCategories().then(({ data }) => {
        this.categories = data.data;
      });
    },

    fetchFreeDeliveries() {
      getFreeDeliveries().then(({ data }) => {
        this.freeDeliveries = data.data;
      });
    },

    async searchIndexes(search) {
      // Clear any existing timeout
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      // Set new timeout for debounce
      this.searchTimeout = setTimeout(async () => {
        if (!search || search.length < 2) return;

        this.isLoadingIndexes = true;
        try {
          const { data } = await quickSearch('pl', search);
          this.indexes = data.data.map((item) => ({
            id: item.id,
            name: `(${item.sku}) ${item.name || ''}`,
            sku: item.sku,
          }));
        } catch (error) {
          console.error('Error searching products:', error);
          this.showErrorNotification('Nie udało się wyszukać produktów');
        } finally {
          this.isLoadingIndexes = false;
        }
      }, 300); // 300ms debounce time
    },

    validationForm() {
      if (this.formData.type === 'category') {
        if (this.formData.selectedCategories.length === 0) {
          this.showFormValidationNotification('Musisz wybrać przynajmniej jedną kategorię');
          return;
        }
      } else if (this.formData.type === 'overcategory') {
        if (this.formData.selectedOvercategories.length === 0) {
          this.showFormValidationNotification('Musisz wybrać przynajmniej jedną nadkategorię');
          return;
        }
      } else if (this.formData.type === 'subcategory') {
        if (this.formData.selectedSubcategories.length === 0) {
          this.showFormValidationNotification('Musisz wybrać przynajmniej jedną podkategorię');
          return;
        }
      } else if (this.formData.type === 'indexes') {
        if (this.formData.selectedIndexes.length === 0) {
          this.showFormValidationNotification('Musisz wybrać przynajmniej jeden indeks');
          return;
        }
      }

      this.onSubmit();
    },

    async onSubmit() {
      const payload = {
        type: this.formData.type,
        itemsCount: this.formData.itemsCount,
        indexes: this.formData.type === 'indexes' ? this.formData.selectedIndexes.map((item) => item.sku) : null,
        category_ids:
          this.formData.type === 'category' ? this.formData.selectedCategories.map((item) => item.id) : null,
        overcategory_ids:
          this.formData.type === 'overcategory' ? this.formData.selectedOvercategories.map((item) => item.id) : null,
        subcategory_ids:
          this.formData.type === 'subcategory' ? this.formData.selectedSubcategories.map((item) => item.id) : null,
        is_active: this.formData.is_active,
        valid_until: this.formData.valid_until ?? null,
      };

      if (this.isEditMode) {
        // If in edit mode, first delete the existing delivery
        try {
          await deleteFreeDelivery(this.editDeliveryId);
          // Then create a new one with updated data
          const response = await submit(payload);
          this.showSuccessNotification('Darmowa dostawa została zaktualizowana');
          this.fetchFreeDeliveries(); // Refresh the list
          this.resetForm(); // Reset the form
        } catch (error) {
          this.showErrorNotification('Nie udało się zaktualizować darmowej dostawy');
          console.error('Error updating free delivery:', error);
        }
      } else {
        // Regular create flow
        submit(payload)
          .then(() => {
            this.showSuccessNotification('Zapisano pomyślnie');
            this.fetchFreeDeliveries(); // Refresh the list
            this.resetForm(); // Reset the form
          })
          .catch(() => {
            this.showErrorNotification('Nie udało się zapisać');
          });
      }
    },

    resetForm() {
      // Reset the form data to its initial state
      this.formData = {
        type: 'category',
        selectedCategories: [],
        selectedOvercategories: [],
        selectedSubcategories: [],
        selectedIndexes: [],
        itemsCount: 'strict',
        is_active: true,
        valid_until: null,
      };

      // Reset any other variables that need to be cleared
      this.selectedIndexes = null;
      this.selectedCategories = null;
      this.selectedOvercategories = null;
      this.selectedSubcategories = null;

      // Reset edit mode
      this.isEditMode = false;
      this.editDeliveryId = null;
    },

    handleIndexSelected(option) {
      // Check if this item is already selected
      const alreadySelected = this.formData.selectedIndexes.some((item) => item.id === option.id);
      if (!alreadySelected) {
        // Add the entire object, not just the ID
        this.formData.selectedIndexes.push(option);
      }
      // Clear the selection
      this.$nextTick(() => {
        this.selectedIndexes = null;
      });
    },

    handleCategorySelected(option) {
      // Check if this item is already selected
      const alreadySelected = this.formData.selectedCategories.some((item) => item.id === option.id);
      if (!alreadySelected) {
        // Add the entire object, not just the ID
        this.formData.selectedCategories.push(option);
      }
      // Clear the selection
      this.$nextTick(() => {
        this.selectedCategories = null;
      });
    },

    handleOvercategorySelected(option) {
      // Check if this item is already selected
      const alreadySelected = this.formData.selectedOvercategories.some((item) => item.id === option.id);
      if (!alreadySelected) {
        // Add the entire object, not just the ID
        this.formData.selectedOvercategories.push(option);
      }
      // Clear the selection
      this.$nextTick(() => {
        this.selectedOvercategories = null;
      });
    },

    handleSubcategorySelected(option) {
      // Check if this item is already selected
      const alreadySelected = this.formData.selectedSubcategories.some((item) => item.id === option.id);
      if (!alreadySelected) {
        // Add the entire object, not just the ID
        this.formData.selectedSubcategories.push(option);
      }
      // Clear the selection
      this.$nextTick(() => {
        this.selectedSubcategories = null;
      });
    },

    removeIndex(index) {
      this.formData.selectedIndexes = this.formData.selectedIndexes.filter((item) => item.id !== index.id);
    },

    removeCategory(category) {
      this.formData.selectedCategories = this.formData.selectedCategories.filter((item) => item.id !== category.id);
    },

    removeOvercategory(overcategory) {
      this.formData.selectedOvercategories = this.formData.selectedOvercategories.filter(
        (item) => item.id !== overcategory.id,
      );
    },

    removeSubcategory(subcategory) {
      this.formData.selectedSubcategories = this.formData.selectedSubcategories.filter(
        (item) => item.id !== subcategory.id,
      );
    },

    getCategoryName(categoryId) {
      const category = this.categories.find((c) => c.id == categoryId);
      return category ? category.name : 'Nieznana kategoria';
    },

    getOvercategoryName(overcategoryId) {
      const overcategory = this.overcategories.find((c) => c.id == overcategoryId);
      return overcategory ? overcategory.name : 'Nieznana nadkategoria';
    },

    getSubcategoryName(subcategoryId) {
      const subcategory = this.subcategories.find((c) => c.id == subcategoryId);
      return subcategory ? subcategory.name : 'Nieznana podkategoria';
    },

    getDeliveryRuleText(delivery) {
      if (delivery.type === 'category') {
        return delivery.itemsCount === 'strict'
          ? 'Tylko produkty z wybranej kategorii'
          : 'Dowolne produkty (gdy w koszyku jest produkt z kategorii)';
      } else if (delivery.type === 'overcategory') {
        return delivery.itemsCount === 'strict'
          ? 'Tylko produkty z wybranej nadkategorii'
          : 'Dowolne produkty (gdy w koszyku jest produkt z nadkategorii)';
      } else if (delivery.type === 'subcategory') {
        return delivery.itemsCount === 'strict'
          ? 'Tylko produkty z wybranej podkategorii'
          : 'Dowolne produkty (gdy w koszyku jest produkt z podkategorii)';
      } else {
        return delivery.itemsCount === 'strict'
          ? 'Tylko wybrane produkty'
          : 'Dowolne produkty (gdy w koszyku jest wybrany produkt)';
      }
    },

    formatDate(dateString) {
      if (!dateString) return '';
      const date = new Date(dateString);
      return date.toLocaleDateString('pl-PL');
    },

    deleteDelivery(id) {
      this.$bvModal
        .msgBoxConfirm('Czy na pewno chcesz usunąć tę darmową dostawę?', {
          title: 'Potwierdzenie',
          okVariant: 'danger',
          okTitle: 'Usuń',
          cancelTitle: 'Anuluj',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.performDelete(id);
          }
        });
    },

    async performDelete(id) {
      try {
        await deleteFreeDelivery(id);
        this.showSuccessNotification('Darmowa dostawa została usunięta');
        this.fetchFreeDeliveries();
      } catch (error) {
        this.showErrorNotification('Nie udało się usunąć darmowej dostawy');
      }
    },

    toggleActiveStatus(delivery) {
      // Show confirmation dialog
      const action = delivery.is_active ? 'dezaktywować' : 'aktywować';
      const statusText = delivery.is_active ? 'nieaktywna' : 'aktywna';

      this.$bvModal
        .msgBoxConfirm(`Czy na pewno chcesz ${action} tę darmową dostawę?`, {
          title: 'Potwierdzenie',
          okVariant: delivery.is_active ? 'warning' : 'success',
          okTitle: delivery.is_active ? 'Dezaktywuj' : 'Aktywuj',
          cancelTitle: 'Anuluj',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.performToggleActiveStatus(delivery);
          }
        });
    },

    async performToggleActiveStatus(delivery) {
      try {
        // Clone the delivery and toggle the is_active status
        const updatedDelivery = {
          ...delivery,
          is_active: !delivery.is_active,
        };

        const response = await updateFreeDeliveryStatus(updatedDelivery.id, updatedDelivery.is_active);

        // For now, we'll just show a success message
        const statusText = updatedDelivery.is_active ? 'aktywowana' : 'dezaktywowana';
        this.showSuccessNotification(`Darmowa dostawa została ${statusText}`);

        // Update the list
        this.fetchFreeDeliveries();
      } catch (error) {
        this.showErrorNotification('Nie udało się zmienić statusu darmowej dostawy');
        console.error('Error updating free delivery status:', error);
      }
    },

    async editDelivery(delivery) {
      // Set edit mode
      this.isEditMode = true;
      this.editDeliveryId = delivery.id;

      // Make sure we have the necessary data before editing
      if (
        (delivery.type === 'overcategory' && (!this.overcategories || this.overcategories.length === 0)) ||
        (delivery.type === 'subcategory' && (!this.subcategories || this.subcategories.length === 0))
      ) {
        this.showLoader('Pobieranie danych...');

        // Fetch data if needed
        const promises = [];
        if (delivery.type === 'overcategory' && (!this.overcategories || this.overcategories.length === 0)) {
          promises.push(this.fetchOvercategories());
        }
        if (delivery.type === 'subcategory' && (!this.subcategories || this.subcategories.length === 0)) {
          promises.push(this.fetchSubcategories());
        }

        if (promises.length > 0) {
          try {
            await Promise.all(promises);
          } catch (error) {
            console.error('Error fetching required data:', error);
          } finally {
            this.hideLoader();
          }
        }
      }

      // Scroll to the form
      this.$nextTick(() => {
        const formElement = this.$refs.formContainer;
        if (formElement) {
          formElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      });

      // Populate form with delivery data
      this.formData.type = delivery.type;
      this.formData.itemsCount = delivery.itemsCount;
      this.formData.is_active = delivery.is_active;
      this.formData.valid_until = delivery.valid_until;

      // Clear existing selections
      this.formData.selectedCategories = [];
      this.formData.selectedOvercategories = [];
      this.formData.selectedSubcategories = [];
      this.formData.selectedIndexes = [];

      // Reset the v-model variables (needed for the dropdown to work correctly)
      this.selectedCategories = null;
      this.selectedOvercategories = null;
      this.selectedSubcategories = null;
      this.selectedIndexes = null;

      // Populate categories if needed
      if (delivery.type === 'category' && delivery.category_ids && delivery.category_ids.length > 0) {
        // Get category objects from IDs
        delivery.category_ids.forEach((id) => {
          const category = this.categories.find((c) => c.id == id);
          if (category) {
            this.formData.selectedCategories.push(category);
          }
        });
      }

      // Populate overcategories if needed
      if (delivery.type === 'overcategory' && delivery.overcategory_ids && delivery.overcategory_ids.length > 0) {
        // Get overcategory objects from IDs
        delivery.overcategory_ids.forEach((id) => {
          const overcategory = this.overcategories.find((c) => c.id == id);
          if (overcategory) {
            this.formData.selectedOvercategories.push(overcategory);
          }
        });
      }

      // Populate subcategories if needed
      if (delivery.type === 'subcategory' && delivery.subcategory_ids && delivery.subcategory_ids.length > 0) {
        // Get subcategory objects from IDs
        delivery.subcategory_ids.forEach((id) => {
          const subcategory = this.subcategories.find((c) => c.id == id);
          if (subcategory) {
            this.formData.selectedSubcategories.push(subcategory);
          } else {
            // If the subcategory is not found, create a placeholder object
            this.formData.selectedSubcategories.push({
              id: id,
              name: `Podkategoria ID: ${id}`,
            });
          }
        });
      }

      // Populate indexes if needed
      if (delivery.type === 'indexes' && delivery.indexes && delivery.indexes.length > 0) {
        // For indexes, create simple objects with just the SKU
        delivery.indexes.forEach((sku) => {
          this.formData.selectedIndexes.push({
            id: sku,
            name: sku,
            sku: sku,
          });
        });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.free-deliveries-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 10px;
}

.selected-indexes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.selected-categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 10px;
  margin-top: 10px;
}

.selected-index-item,
.selected-category-item {
  position: relative;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  padding: 15px;
  background-color: #f8f8f8;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  overflow: hidden;
}

.index-content,
.category-content {
  padding-right: 20px;
  word-break: break-word;
}

.index-remove-btn,
.category-remove-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  padding: 0;
  color: #333 !important;
  opacity: 0.7;
  transition: opacity 0.2s;
}

.index-remove-btn:hover,
.category-remove-btn:hover {
  opacity: 1;
}

.free-delivery-card {
  height: 100%;
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border: 1px solid #eee;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  }

  &.inactive-card {
    background-color: #f9f9f9;
    border: 1px #ddd;

    &:hover {
      border: 1px #ccc;
    }
  }
}

.card-header-section {
  position: relative;
  height: 8px;
  background-color: #f5f5f5;

  .status-indicator {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.status-active {
      background: linear-gradient(90deg, rgba(40, 199, 111, 0.5) 0%, rgba(40, 199, 111, 0) 100%);
    }

    &.status-inactive {
      background: linear-gradient(90deg, rgba(234, 84, 85, 0.5) 0%, rgba(234, 84, 85, 0) 100%);
    }
  }

  .delivery-type-badge {
    position: absolute;
    top: 100%;
    right: 15px;
    transform: translateY(-50%);
  }
}

.delivery-title {
  padding-top: 10px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
}

.delivery-status {
  display: flex;
  align-items: center;

  .delivery-status-text {
    font-weight: 600;
    font-size: 0.85rem;
  }
}

.delivery-details {
  padding-top: 10px;
}

.rule-label,
.indexes-title {
  font-size: 0.85rem;
  margin-right: 5px;
}

.rule-value {
  font-size: 0.9rem;
}

.card-body-content {
  flex: 1 1 auto;
  overflow: auto;
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.card-actions {
  padding: 0.85rem 1.25rem;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  background-color: white;
  margin-top: auto;
}

.delivery-indexes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 8px;
  margin-top: 8px;
}

.delivery-index-item {
  font-size: 0.8rem;
  padding: 6px 10px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.categories-container {
  margin-top: 10px;
}

.categories-title {
  font-size: 0.85rem;
  margin-right: 5px;
}

.delivery-categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  gap: 8px;
  margin-top: 8px;
}

.delivery-category-item {
  font-size: 0.8rem;
  padding: 6px 10px;
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  white-space: normal;
  min-height: 40px;
  width: 100%;
  word-break: break-word;
  display: flex;
  align-items: center;
  justify-content: center;
}

.categories-count {
  text-align: right;
  margin-top: 5px;
}

// Responsive adjustments for smaller screens
@media (max-width: 1199.98px) {
  .free-deliveries-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 767.98px) {
  .free-deliveries-grid {
    grid-template-columns: 1fr;
  }
}

/* Fix for item display to prevent text truncation */
.delivery-index-item {
  white-space: normal !important;
  overflow: visible !important;
  text-overflow: clip !important;
  min-height: 40px;
  align-items: flex-start !important;
  word-break: break-word;
}

.selected-index-item,
.selected-category-item {
  overflow: visible !important;
  min-height: 50px;
}
</style>
